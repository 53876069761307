
  
  form {
    max-width: 900px;
    margin: 0 auto;
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  fieldset {
    border: 2px solid #042038;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 10px 20px;
  }
  
  legend {
    background-color: #042038;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
  }
  
  label {
    display: block;
    margin: 10px 0 5px;
  }
  
  input[type="text"],
  input[type="email"] {
    width: calc(100% - 22px);
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  input[type="submit"] {
    background-color: #F77522;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  input[type="submit"]:hover {
    background-color: #042038;
  }
  
  /* CSS for the button */
button[type="submit"] {
  background-color: #F77522; /* Set default background color */
  color: white; /* Set text color */
  padding: 10px 20px; /* Add padding */
  border: none; /* Remove border */
  border-radius: 5px; /* Add border radius */
  cursor: pointer; /* Add cursor pointer */
  transition: background-color 0.3s; /* Add transition effect for background color */
}

/* CSS for button hover effect */
button[type="submit"]:hover {
  background-color: #042038; /* Change background color on hover */
}
