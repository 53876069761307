
.demo-option-wrapper {
    height: 100%;
    overflow-y: auto;
    padding-bottom: 55px;
}

.demo-option-wrapper .demo-panel-header {
    padding: 40px 30px 30px;
    text-align: center;
}

.demo-option-container {
    position: fixed;
    top: 0;
    left: 100%;
    background: #fff;
    height: 100%;
    width: 350px;
    max-width: calc(100% - 45px);
    z-index: 100000;
    -webkit-transition: all .5s;
    -o-transition: all .5s;
    transition: all .5s;
}

.demo-option-container.open {
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
    -webkit-box-shadow: -3px 0 50px -2px rgba(0, 0, 0, 0.14);
    box-shadow: -3px 0 50px -2px rgba(0, 0, 0, 0.14);
}

.demo-quick-option-list {
    padding: 27px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
}

.demo-quick-option-list a img {
    border-radius: 5px;
}

.demo-quick-option-list a:hover img {
    -webkit-transform: translateY(-3px);
    -ms-transform: translateY(-3px);
    transform: translateY(-3px);
    -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.aeroland__toolbar .inner a {
    margin-top: 126px;
    display: block;
    width: 47px;
    height: 56px;
    line-height: 44px;
    color: #222;
    font-size: 20px;
    font-weight: 600;
    transform: rotate(-90deg);
    display: block;
    white-space: nowrap;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.aeroland__toolbar .inner {
    
    position: absolute;
    top: 210px;
    right: 100%;
    display: block;
    width: 39px;
    border: 1px solid #eee;
    border-right: 0;
    border-radius: 5px 5px 5px 25px;
    background:#F77522 !important;
    text-align: center;
    height: 181px;
    -webkit-box-shadow: -3px 0 10px -2px rgba(0, 0, 0, 0.1);
    box-shadow: -3px 0 10px -2px rgba(0, 0, 0, 0.1);
}
.toast-container-left {
    left: 20px;
}
/* .aeroland__toolbar .inner {
    position: absolute;
    top: 200px;
    right: 100%;
    display: block;
    width: 40px;
    border: 1px solid #eee;
    border-right: 0;
    border-radius: 5px 0 0 5px;
    background: #fff;
    text-align: center;
    -webkit-box-shadow: -3px 0 10px -2px rgba(0, 0, 0, 0.1);
    box-shadow: -3px 0 10px -2px rgba(0, 0, 0, 0.1);
}

.aeroland__toolbar .inner a {
    display: block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #222;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    -o-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
} */
.business-solution-form-wrap {
    max-width: 400px;
    margin-left: auto;
    background: #fff;
    -webkit-box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 30px rgba(0, 0, 0, 0.1);
    padding: 42px 30px 51px;
    border-radius: 5px;
    text-align: center;
}

@media only screen and (min-width: 768px) and (max-width: 991px),
only screen and (max-width: 767px) {
    .business-solution-form-wrap {
        margin: auto;
    }
    .aeroland__toolbar .inner {
        margin-top: 150px;
    }
}