@import url("https://fonts.googleapis.com/css2?family=Inter:wght@900&display=swap");





.body__inner-wrapper {
  height: 100%;
  max-width: 104rem;
  display: flex;
  flex-direction: column;
  gap: 6rem;
  justify-content: center;
  margin-inline: auto;
  margin-block-start: 6rem;
}


.marquee {
  width: 100%;
  height: 70vh;
  pointer-events: none;

  overflow: hidden;
 margin-bottom: 80px;
}

.marquee__inner-wrap {
  height: 100%;
  width: 100%;

  justify-content: center;
  align-items: center;
}

.marquee__inner {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  animation: marquee 50s linear infinite;
  will-change: transform;
}

.marquee__item {
  display: flex;
  align-items: center;
  margin: 2vh 0;
}

.marquee__img {
  width: max(8rem, 12vw);
  height: 8rem;
  margin-right: 2vw;
  border-radius: 100rem;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #444;
  filter: grayscale(0.6);
}

.marquee span {
  color: lightgray;
  font-weight: 400;
 
  font-weight: 700;
  text-transform: uppercase;
}

@keyframes marquee {
  to {
    transform: translateY(-50%);
  }
}

.card2 {
	background-color: #fff;
	box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
	border-radius: 20px;
	display: flex;
	flex-direction: row;
	padding: 30px;
	max-width: 500px;
	min-height: 130px;
	margin: 7px;
	transition: all 0.5s ease;
}

.card2:hover {
	box-shadow: 8px 28px 50px rgba(39, 44, 49, 0.07),
		1px 6px 12px rgba(39, 44, 49, 0.04);
	transform: translate3D(0, -1px, 0) scale(1.02);
	transition: all 0.5s ease;
}

.profile_img {
	width: 80px;
	height: 80px;

	object-fit: cover;
	object-position: 50% 50%;

	border-radius: 100%;
}

.flag_wrapper {
	width: 25px;
	height: 25px;
	background-color: #f2f2f2;
	border-radius: 100%;
	position: relative;
	top: -30px;
	left: 55px;
}

.flag {
	width: 15px;
	height: 15px;
	position: absolute;
	right: 0;
	left: 0;
	top: 0;
	bottom: 0;
	margin: auto auto;
}

.left {
	margin-right: 12.5px;
}
.right {
	margin-left: 12.5px;
}

.interests {
	margin-top: 10px;
}

.interests_title {
	color: #222222;
	margin-bottom: 5px;
}

.interests_item {
	padding: 0 15px;
	margin-right: 5px;
	line-height: 35px;
	background-color: #e6e6e6;
	border-radius: 5px;

	display: inline-block;
}

.resposnive{
	margin-left: 80px;
 }

 .resposnive_marquee{
	margin-left: 70px;
 }

@media screen and (max-width: 768px) {
	 .resposnive{
		margin-left: 0px;
	 }

	 .resposnive_marquee{
		margin-left: 0px;
	 }
	 .text1{
		width: 120%;
		margin-left: 5px;
	 }
}