.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    grid-gap: 20px;
    align-items: stretch;
  }
  
  .grid > article {
    border: 1px solid #ccc;
    box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
  }
  
  .grid > article img {
    max-width: 100%;
  }
  
  .grid .text {
    padding: 20px;
  }
  