
.container_gallery {
	margin-top: 30px;
	padding: 0 2rem 2rem;
}

.heading {
	font-family: "Montserrat", Arial, sans-serif;
	font-size: 4rem;
	font-weight: 500;
	line-height: 1.5;
	text-align: center;
	padding: 3.5rem 0;
	color: #1a1a1a;
}

.heading span {
	display: block;
}

.gallery {
	display: flex;
	flex-wrap: wrap;
	/* Compensate for excess margin on outer gallery flex items */
	margin: -1rem -1rem;
}

.gallery-item {
	/* Minimum width of 24rem and grow to fit available space */
	flex: 1 0 24rem;
	/* Margin value should be half of grid-gap value as margins on flex items don't collapse */
	margin: 1rem;
	box-shadow: 0.3rem 0.4rem 0.4rem rgba(0, 0, 0, 0.4);
	overflow: hidden;
}

.gallery-image {
	display: block;
	width: 100%;
	height: 100%;
	object-fit: cover;
	transition: transform 400ms ease-out;
}

.gallery-image:hover {
	transform: scale(1.15);
}

/*

The following rule will only run if your browser supports CSS grid.

Remove or comment-out the code block below to see how the browser will fall-back to flexbox styling. 

*/

@supports (display: grid) {
	.gallery {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
		grid-gap: 2.8rem;
	}

	.gallery,
	.gallery-item {
		margin: 0;
	}
}



/* simple gallery css */
.container-all{
    width: fit-content;
    margin: 20px auto;
    height: auto;
    display: flex;
   
  }
  .container_other{
    width: calc(33% - 6px);
    overflow:hidden;
    height: fit-content;
    margin:3px;
    padding: 0;
    display:block;
    position:relative;
    
  }
  .simple_gallery_image{
    width: 100%;
    transition-duration: .3s;
    max-width: 100%;
    display:block;
    overflow:hidden;
    cursor:pointer;
  }
  .title_other{
    position:absolute;
    display:block;
    cursor:pointer;
    top: 35%;
    display: none;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    font-weight: bold;
    font-size: 1.6em;
    text-shadow: 1px 5px 10px black;
    transition-duration: .3s;
  }
  .text_other{
    position:absolute;
    top: 59%;
    cursor:pointer;
    max-width: 80%;
    text-align:center;
    left: 50%;
    text-shadow: 1px 5px 10px black;
    font-size: 12.2px;
    font-weight: 800;
    display:none;
    margin-right: -50%;
    transition-duration: .3s;
    transform: translate(-50%, -50%) 
  }
  .container_other:hover .simple_gallery_image{
    transform: scale(1.2);
    transition-duration: .3s;
    filter: grayscale(50%);
    opacity: .7;
  }
  .container_other:hover span{
    color:rgb(0, 0, 0);
    display: block;
    transition-duration: .3s;
  }
  @media only screen and (max-width: 900px) {
      .container_other {
          width: calc(50% - 6px);
      }
  }
  @media only screen and (max-width: 400px) {
      .container_other {
          width: 100%;
      }
  }

  @media screen and (max-width: 768px) {
    .container-all{
       display: flex;
       flex-direction: column;
       padding: 20px;
    }

    .flip-card{
      width: 340px;
    }
  }


  /* new event gallery flip cards*/
  .flip-card {
    background-color: transparent;
    width: 300px;
    height: 254px;
    perspective: 1000px;
    font-family: sans-serif;
    margin: 10px;
  }
  
  .title {
    font-size: 1.5em;
    font-weight: 900;
    text-align: center;
    margin: 0;
  }
  
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
    box-shadow: 0 8px 14px 0 rgba(0,0,0,0.2);
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border: 1px solid coral;
    border-radius: 1rem;
  }
  
  .flip-card-front {
    background: linear-gradient(120deg, bisque 60%, rgb(255, 231, 222) 88%,
       rgb(255, 211, 195) 40%, rgba(255, 127, 80, 0.603) 48%);
    color: coral;
  }
  
  .flip-card-back {
    background: linear-gradient(120deg, rgb(255, 174, 145) 30%, coral 88%,
       bisque 40%, rgb(255, 185, 160) 78%);
    color: white;
    transform: rotateY(180deg);
  }










  .team-block {
    margin-bottom: 10px; /* Adjust as needed to reduce vertical spacing */
    padding: 0 5px; /* Adjust as needed to reduce horizontal spacing */
  }

  .row.clearfix {
   

  /* Adjust to match the padding on .team-block */
  }



  .inner-box {
    width: 100%;
  }
  .field{
    width: 1500px;
  }
  
  @media screen and (max-width: 768px){
    .row.clearfix{
      width: 100%;
      margin-left: 0px;
    }
   
  }


  @media screen and (min-width: 769px) {
     .resposnive_new_heading_32{
      display: none;
     }
  }