
.slider {
    /* background: white;
    box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125); */
    margin: auto;
    overflow: hidden;
    position: relative;
 
}

.slider::before,
.slider::after {
    /* background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%); */
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
}

.slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
}

.slider::before {
    left: 0;
    top: 0;
}

.slide-track {
    animation: scroll 40s linear infinite;
    display: flex;
    width: calc(250px * 14);
}

.slide {
    width:  200px;
    height: 100px;
    object-fit: cover;
    margin-bottom: 20px;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-250px * 7));
    }
}


/* certificate css */
.certification_container{
    display: flex;
    justify-content: center;
}


.certificate_image{
    object-fit: fill;
}

figure.snip1200 {
   
    position: relative;
    overflow: hidden;
    margin: 10px;
    min-width: 220px;
    max-width: 320px;
    max-height: 390px;
    width: 100%;
    background: #000000;
    color: #ffffff;
    text-align: center;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    font-size: 16px;
  }
  figure.snip1200 * {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all 0.45s ease-in-out;
    transition: all 0.45s ease-in-out;
  }
  figure.snip1200 img {
    max-width: 100%;
    position: relative;
    opacity: 0.9;
  }
  figure.snip1200 figcaption {
    position: absolute;
    top: 45%;
    left: 7%;
    right: 7%;
    bottom: 45%;
    border: 1px solid white;
    border-width: 1px 1px 0;
  }
  figure.snip1200 .heading {
    overflow: hidden;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    position: absolute;
    bottom: 0;
    width: 100%;
  }
  figure.snip1200 h2 {
    display: table;
    margin: 0 auto;
    padding: 0 10px;
    position: relative;
    text-align: center;
    width: auto;
    text-transform: uppercase;
    font-weight: 400;
  }
  figure.snip1200 h2 span {
    font-weight: 800;
  }
  figure.snip1200 h2:before,
  figure.snip1200 h2:after {
    position: absolute;
    display: block;
    width: 1000%;
    height: 1px;
    content: '';
    background: white;
    top: 50%;
  }
  figure.snip1200 h2:before {
    left: -1000%;
  }
  figure.snip1200 h2:after {
    right: -1000%;
  }
  figure.snip1200 p {
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    position: absolute;
    width: 100%;
    padding: 0 20px;
    margin: 0;
    opacity: 0;
    line-height: 1.6em;
    font-size: 0.9em;
  }
  figure.snip1200 a {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: 1;
  }
  figure.snip1200:hover img,
  figure.snip1200.hover img {
    opacity: 0.25;
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
  figure.snip1200:hover figcaption,
  figure.snip1200.hover figcaption {
    top: 7%;
    bottom: 7%;
  }
  figure.snip1200:hover p,
  figure.snip1200.hover p {
    opacity: 1;
    -webkit-transition-delay: 0.35s;
    transition-delay: 0.35s;
  }
  

  @media screen and (max-width: 600px){
    .certification_container{
       display: flex;
       flex-direction: column;
    }

    .resposnive_home_aboutus_vission{
         display: none;
    }
  }
  
  @media screen and (min-width: 768px){
    .resposnive_home_aboutus_vission_other{
       display: none;
    }
    .mobile_only_have_image{
      display: none;
    }
  }
  