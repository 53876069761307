.contentDiv {
    width: 300px;
    height: 1000px;
    background-color: #ff0000;
  }
  
  .back-to-top {
    margin: 0 !important;
    padding: 0 !important;
    background: #fff;
    height: 0px;
    width: 0px;
    overflow: hidden;
    border-radius: 50px;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    color: transparent;
    clear: both;
    visibility: hidden;
    position: fixed;
    cursor: pointer;
    display: block;
    border: none;
    right: 50px;
    bottom: 75px;
    font-size: 0px;
    outline: 0 !important;
    z-index: 99;
    transition: all .3s ease-in-out;
  }
  
  .back-to-top:hover,
  .back-to-top:active,
  .back-to-top:focus {
    outline: 0 !important;
  }
  
  .back-to-top::before,
  .back-to-top::after {
    content: "";
    display: block;
    vertical-align: middle;
    border-bottom: solid 10px #EA5D5F;
    border-left: solid 10px transparent;
    line-height: 0;
    border-right: solid 10px transparent;
    height: 0;
    margin: 18px auto 0;
    width: 0;
    border-radius: 20px;
    visibility: hidden;
  }
  
  .back-to-top.show::after,
  .back-to-top.show::before {
    visibility: visible;
  }
  
  .back-to-top::after {
    border-bottom-color: #fff;
    position: relative;
    top: -24px;
  }
  
  .back-to-top.show {
    display: block;
    background: #fff;
    color: #00ab6c;
    font-size: 25px;
    right: 25px;
    bottom: 50px;
    height: 50px;
    width: 50px;
    visibility: visible;
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.25);
  }
  
  .back-to-top.show:hover {
    background: #142340; /* Change to blue color on hover */
    color: #fff; /* Change text color to white on hover */
  }
  
  .back-to-top.show:active {
    box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.25);
  }