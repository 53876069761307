.card_dd {
  --bg: #f7f7f8;
  --hover-bg: #F77522;
  --hover-text: #ffffff;
  max-width: 250px;
  text-align: center;
  background: var(--bg);
  padding: 1.5em;
  padding-block: 1.8em;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  transition: .3s cubic-bezier(.6,.4,0,1),transform .15s ease;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.card__body {
  color: #464853;
  line-height: 1.5em;
  font-size: 1em;
}

.card_dd > :not(span) {
  transition: .3s cubic-bezier(.6,.4,0,1);
}

.card_dd > strong {
  display: block;
  font-size: 1.4rem;
  letter-spacing: -.035em;
}

.card_dd span {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--hover-text);
  border-radius: 5px;
  font-weight: bold;
  top: 100%;
  transition: all .3s cubic-bezier(.6,.4,0,1);
}

.card_dd:hover span {
  top: 0;
  font-size: 1.2em;
}

.card_dd:hover {
  background: var(--hover-bg);
}

.card_dd:hover>div,.card_dd:hover>strong {
  opacity: 0;
}


.responsive_slider{
   margin-left: 100px;
   margin-right: 100px;
   padding-bottom: 17px;
}

@media screen and (max-width: 768px) {
  .responsive_slider{
    margin-left: 0px;
    margin-right: 0px;
    padding-bottom: 20px;
 }

 .serviceslider_container{
  background-color: #042038;
  background-repeat: no-repeat;
  background-size: cover;
  /* border-top-right-radius: 60px;
  border-bottom-left-radius: 70px; */
  margin-top: -27px;
  padding: 10px;
}

}

.serviceslider_container{
     background-color: #042038;
     background-repeat: no-repeat;
     background-size: cover;
     /* border-top-right-radius: 60px;
     border-bottom-left-radius: 70px; */
     margin-top: -27px;
     padding: 10px;
}