#customers {
  
    border-collapse: collapse;
    width: 100%;
    margin-left: 8px;
}

#customers td, #customers th {
    border: 1px solid #ddd;
    padding: 8px;
}

#customers tr:nth-child(even) {
    background-color: #f2f2f2;
}

#customers tr:hover {
    background-color: #ddd;
}

#customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #F77522;
    color: white;
}

.resposive_revenue_container{
    display: flex;
}

/* Responsive styles */
@media screen and (max-width: 600px) {
    #customers thead {
        display: none;
    }

    #customers tr {
        border: 1px solid #ddd;
        margin-bottom: 10px;
        display: block;
    }

    #customers td {
        display: block;
 
        border: none;
        border-bottom: 1px solid #ddd;
        position: relative;
        padding-left: 50%;
    }

    #customers td::before {
        content: attr(data-label);
        position: absolute;
        left: 0;
        width: 50%;
        padding-left: 15px;
        font-weight: bold;
        text-align: left;
    }


    .resposive_revenue_container{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}


  
 


   .cont{
    margin-left: 30px;
    width: 600px;
   }

   .responsive_expensive{
    margin-left: 130px;
   }
@media (max-width: 600px) {
  .form-container,
  .card-container {
    width: 100%;
  }

  .card {
    max-width: 100%; /* Cards take full width on small screens */
  }


  .card-container{
    margin-left: 0px;
  }
  .cont{
    margin-left: 0px;
   }
   .responsive_expensive{
    margin-left: 0px;
   }
}


@media screen and (max-width: 768px) {
    .card-container{
       display: flex;
    }
}

/* Container for centering and spacing */
.form-container {
 
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: 0 auto;
  
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Style for the form groups as boxes */
  .form-group1 {
    display: flex;
    flex-direction: column;
    padding: 15px;
    margin-bottom: 15px;
    width: 40%;
    background-color: #ffffff;

    border-radius: 4px;
   
  }
  
  /* Style for the labels */
  .form-group label {
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
  }
  
  /* Style for the input fields */
  .form-group input[type="date"] {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
  }
  
  /* Style for the button */
  button {
    padding: 10px 20px;
    background-color: #F77522;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 30%;
    box-sizing: border-box;
  }
  
  button:disabled {
    background-color: #fb5408;
   
  }
  
  button .spinner {
    border: 2px solid #f3f3f3;
    border-top: 2px solid #007bff;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  .button1{
    width: 100%;
  }