/* Write your CSS here */
.appcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
  }
  
  .cardcontainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  
  
  .heading {
   
    font-size: 34px;
    color: #1e293b;
  }
  
  .search-input-container {
    display: flex;
    align-items: center;
    height: 40px;
    background-color: transparent;
    width: 100%;
    max-width: 250px;
    border-radius: 4px;
    border: 1px solid #7b8794;
    padding-left: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 16px;
  }
  
  .search-input {
  
    font-size: 16px;
    font-weight: 500;
    border: none;
    outline: none;
    background-color: transparent;
    flex-grow: 1;
  }
  
  .upcontainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .searchplaceholder {
    color: #7b8794;
  }
  
  .search_icon {
    height: 23px;
    width: 23px;
  }
  
  .tabitemcontainer {
    display: flex;
    justify-content: center;
    gap: 30px;
  }
  
  .appcontainer {
    display: flex;
    flex-wrap: wrap;
  }
  