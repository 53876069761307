
.appItemcontainer {
  background-color: white;
  background-size: cover;
  border-radius: 7px;
  padding: 10px;
  margin: 10px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  width: 360px;
}

.appimage {
  height: 195px;
  width: 303px;
  border-radius: 9px;
}

.appname {

  color: #1e293b;
}


.learn_more{
  width: max-content;
}