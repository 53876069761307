.slider {
  overflow: hidden;
  position: relative;
  height: 70vh;
  color: #fff;
  width: 100%;
}

.slider__top-heading {
  z-index: 12;
  position: absolute;
  left: 0;
  top: 100px;
  width: 100%;
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  transition: all 0.5s 1s;
  transform: translateY(-30px);
  opacity: 0;
}

.slider.s--ready .slider__top-heading {
  transform: translateY(0);
  opacity: 1;
}

.slider__slides {
  position: relative;
  height: 100%;
}

.slider__slide {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.slider__slide.s--active {
  pointer-events: auto;
  opacity: 1;
}

.slider__slide.s--prev {
  opacity: 0;
}

.slider__slide-content {
  z-index: 6;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  text-transform: uppercase;
  line-height: 1;
  text-align: center;
}

.slider__slide-subheading {
  margin-bottom: 20px;
  font-size: 24px;
  letter-spacing: 2px;
  transform: translateY(20px);
  opacity: 0;
  transition: 0.5s;
}

.slider__slide.s--active .slider__slide-subheading {
  transition-delay: 0.65s;
  opacity: 1;
  transform: translateY(0);
}

.slider__slide-heading {
  display: flex;
  margin-bottom: 20px;
  font-size: 60px;
  letter-spacing: 12px;
}

.slider__slide-heading span {
  display: block;
  opacity: 0;
  transform: translateY(-60px);
  transition: all 0.33333s;
}

.slider__slide.s--prev .slider__slide-heading span {
  transform: translateY(60px);
}

.slider__slide.s--active .slider__slide-heading span {
  opacity: 1;
  transform: translateY(0);
}

.slider__slide.s--active .slider__slide-heading span:nth-child(1) {
  transition-delay: 0s;
}

.slider__slide.s--active .slider__slide-heading span:nth-child(2) {
  transition-delay: 0.1s;
}

.slider__slide.s--active .slider__slide-heading span:nth-child(3) {
  transition-delay: 0.2s;
}

.slider__slide.s--active .slider__slide-heading span:nth-child(4) {
  transition-delay: 0.3s;
}

.slider__slide.s--active .slider__slide-heading span:nth-child(5) {
  transition-delay: 0.4s;
}

.slider__slide.s--active .slider__slide-heading span:nth-child(6) {
  transition-delay: 0.5s;
}

.slider__slide.s--active .slider__slide-heading span:nth-child(n+7) {
  transition-delay: 0.6s;
}

.slider__slide-readmore {
  position: relative;
  font-size: 14px;
  backface-visibility: hidden;
  transform: translateY(-20px);
  cursor: pointer;
  opacity: 0;
  transition: 0.5s;
}

.slider__slide.s--active .slider__slide-readmore {
  transition-delay: 0.65s;
  opacity: 1;
  transform: translateY(0);
}

.slider__slide-readmore:before {
  content: '';
  position: absolute;
  left: -2px;
  top: -3px;
  width: calc(100% + 4px);
  height: calc(100% + 6px);
  background: rgba(255, 255, 255, 0.4);
  transform: scaleX(0.3);
  transform-origin: 0 50%;
  transition: transform 0.3s;
}

.slider__slide-readmore:hover:before {
  transform: scaleX(1);
}

.slider__slide-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  transition: opacity 0.5s ease-in-out;
}

.slider__control {
  z-index: 100;
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.4);
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 0.5s ease;
}

.slider__control:before {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  margin-top: -10px;
  border: 2px solid #000;
  border-bottom: none;
  border-right: none;
  transform: rotate(-45deg);
}

.slider__control--right {
  right: 50px;
}

.slider__control--right:before {
  transform: rotate(135deg);
}

@media screen and (max-width: 768px) {
  .slider__slide-heading {
    font-size: 18px;
  }

  .slider__slide-subheading {
    font-size: 20px;
  }

  .slider {
    height: 50vh;
    margin-top: 20px;
  }

  .slider__control--right,
  .slider__control {
    visibility: hidden;
  }

  .slider__slide-content {
    text-align: center;
  }
}
