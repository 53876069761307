
  
  .student-profile .card {
    border-radius: 10px;
  }
  
  .student-profile .card .card-header .profile_img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin: 10px auto;
    border: 10px solid #ccc;
    border-radius: 50%;
  }
  
  .student-profile .card h3 {
    font-size: 20px;
    font-weight: 700;
  }
  
  .student-profile .card p {
    font-size: 16px;
    color: #000;
  }
  
  .student-profile .table th,
  .student-profile .table td {
    font-size: 14px;
    padding: 5px 10px;
    color: #000;
  }

  .resume-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 30px;
}

.resume-table th, .resume-table td {
    padding: 10px;
    border: 1px solid #8f8f8f;
}

.resume-table th {
    background-color: #f2f2f2;
    text-align: left;
}

.resume-table tbody tr:hover {
    background-color: #f2f2f2;
}
/* CSS for the download button */
a.download-button {
  text-decoration: none; /* Remove default underline */
}

a.download-button button {
  background-color: #F77522; /* Set default background color */
  color: white; /* Set text color */
  padding: 10px 20px; /* Add padding */
  border: none; /* Remove border */
  border-radius: 5px; /* Add border radius */
  cursor: pointer; /* Add cursor pointer */
  transition: background-color 0.3s; /* Add transition effect for background color */
}

/* CSS for download button hover effect */
a.download-button button:hover {
  background-color: #042038; /* Change background color on hover */
}

/* CSS for mobile view */
@media screen and (max-width: 768px) {
  .resume-table thead {
    display: none;
  }

  .resume-table, .resume-table tbody, .resume-table tr, .resume-table td {
    display: block;
    width: 100%;
  }

  .resume-table tr {
    margin-bottom: 15px;
  }

  .resume-table td {
   
    position: relative;
  }

  .resume-table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    padding-left: 15px;
    font-weight: bold;
    text-align: left;
  }
}
