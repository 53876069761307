/* this card is inspired form this - https://georgefrancis.dev/ */
.card-container {
    grid-gap: 2rem;
    margin: 23px;
 
    place-items: center;
  }

  .card-container:after {
    content: "";
    display: table;
    clear: both;
  }

  .card_career {
    float: left;
    width: 25%;
    padding: 0 10px;
  }
  

.card_career {
    --border-radius: 0.75rem;
    --primary-color: #7257fa;
    --secondary-color: #3c3852;
    width: 370px;
   
    padding: 1rem;
    cursor: pointer;
    border-radius: var(--border-radius);
    background: #f1f1f3;
    box-shadow: 0px 8px 16px 0px rgb(0 0 0 / 3%);
    position: relative;
    border-top-style: solid;
    border-color: #F77522;
    border-width: 4px;
    margin: 13px;
  }
  
  .card_career > * + * {
    margin-top: 1.1em;
  }
  
  .card_career .card__content {
    color: var(--secondary-color);
    font-size: 15px;
  }
  
  .card_career .card__title {
    padding: 0;
    font-size: 20px;
    font-weight: bold;
  }
  
  .card_career .card__date {
    color: #6e6b80;
    font-size: 15px;
  }
  
  .card_career .card__arrow {
    position: absolute;
    background: var(--primary-color);
    padding: 0.4rem;
    border-top-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    bottom: 0;
    right: 0;
    transition: 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .card_career svg {
    transition: 0.2s;
  }
  
  /* hover */
  .card_career:hover .card__title {
    color: var(--primary-color);
    text-decoration: underline;
  }
  
  .card_career:hover .card__arrow {
    background: #111;
  }
  
  .card_career:hover .card__arrow svg {
    transform: translateX(3px);
  }


  @media screen and (max-width: 600px) {
    .card_career {
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }

    .card-container{
        padding-right: 24px;
        margin-left: 20px;
    }

    .resposnive_apply_job{
      text-align: justify;
      margin: 10px;
    }
  }

  /* Additional CSS for popup */
.modal-content {
  background: #fff;
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  position: relative;
}

.modal-content .close {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

#contact-form .row {
  display: flex;
  flex-wrap: wrap;
}

#contact-form .form-group {
  padding: 10px;
  flex: 1 1 45%;
}

#contact-form .form-group input,
#contact-form .form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

#contact-form .form-group textarea {
  resize: vertical;
  min-height: 100px;
}

#contact-form .theme-btn {
  background-color: #F77522;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

#contact-form .theme-btn:hover {
  background-color: #e03d00;
}
.modal-content .close{
  color: #000000;
  background: linear-gradient(to right, #F77522, #F77522, #F77522);
  font-size: 35px;
  font-weight: 400;
  text-shadow: none;
  line-height: 27px;
  height: 35px;
  width: 35px;

  overflow: hidden;
  opacity: 1;
  position: absolute;
  left: auto;
  right: 8px;
  top: 8px;
  z-index: 1;
  transition: all 0.3s;
}
.modal-content .close:hover{
  color: #fff;
  box-shadow: 0 0 5px rgba(0,0,0,0.5);
}
@media only screen and (max-width: 600px) {
  .modal-content {
    padding: 10px;
  }



}
.container2 {
  height: 300px;
  width: 300px;
  border-radius: 10px;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, .2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  gap: 5px;
  background-color: rgba(0, 110, 255, 0.041);
  height: 100px;
}

.header2 {
  flex: 1;
  width: 100%;
  border: 2px dashed royalblue;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 80px;
}

.header svg {
  height: 100px;
}

.header p {
  text-align: center;
  color: black;
}

.footer {
  background-color: rgba(0, 110, 255, 0.075);
  width: 100%;
  height: 40px;
  padding: 8px;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: black;
  border: none;
  margin-top: 10px;
}

.footer svg {
  height: 130%;
  fill: royalblue;
  background-color: rgba(70, 66, 66, 0.103);
  border-radius: 50%;
  padding: 2px;
  cursor: pointer;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.205);
}

.footer p {
  flex: 1;
  text-align: center;
}

#file {
  display: none;
}
/* Add some basic styles for the form elements */
form input[type="text"],
form input[type="email"],
form input[type="file"],
form textarea,
form select {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

/* Style for the dropdown boxes */
form select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url('data:image/svg+xml;charset=US-ASCII,<svg%20xmlns="http://www.w3.org/2000/svg"%20width="10"%20height="10"%20viewBox="0%200%2010%2010"><polygon%20fill="%23ccc"%20points="0,0%205,5%2010,0"/></svg>') no-repeat right 10px center;
    background-size: 10px;
    padding-right: 30px; /* Make space for dropdown arrow */
}

form select:focus {
    border-color: #007BFF;
    outline: none;
}

form button {
    background-color: orange;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

form button:hover {
    background-color: blue;
}

/* Additional style for disabled button */
form button[disabled] {
    background-color: #ccc;
    cursor: not-allowed;
}




.custom-grid-container {
  padding: 20px 0; /* Reduced padding for the container */
}

.custom-grid-container .grid {
  display: flex;
  flex-wrap: wrap;
  margin: 0; 
  /* Removed margin for the grid */
  gap:20px;
}

.custom-grid-container .grid > article {
  border: 1px solid #ccc;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.3);
  padding: 10px; /* Reduced padding inside articles */
  max-width: 400px; /* Set a maximum width for the cards */
   /* Set a fixed height for the cards */
  display: flex;
  flex-direction: column;
}

.custom-grid-container .grid > article img {
  width: 100%;
  height: 200px; /* Set a fixed height for the images */
  object-fit: cover; /* Ensure the image covers the area without stretching */
}

.custom-grid-container .grid .text {
  padding: 10px; /* Reduced padding for text container */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Responsive Design: Adjust to fewer columns on smaller screens */
@media (max-width: 900px) {
  .custom-grid-container .grid {
    grid-template-columns: repeat(2, 1fr); /* Two columns for medium screens */
  }
}

@media (max-width: 600px) {
  .custom-grid-container .grid {
    grid-template-columns: 1fr; /* One column for small screens */
  }
  
}


@media screen and (min-width: 768px) {
  .custom-grid-container .grid {
    margin-left: 60px;
  }

  .resposnive_apply_job{
    text-align: justify;
    margin: 10px;
    width: 700px;
  }
}