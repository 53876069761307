@import url('https://fonts.googleapis.com/css?family=Roboto');

/* offset-x > | offset-y ^| blur-radius | spread-radius | color */
.shadowBox {
  -webkit-box-shadow: 1px 1px 6px 0px rgba(68, 68, 68, 0.705);
  -moz-box-shadow: 1px 1px 6px 0px rgba(68, 68, 68, 0.705);
  box-shadow: 1px 1px 6px 0px rgba(68, 68, 68, 0.705);
}

@keyframes pulse {
    0% {
        transform: scale(1, 1);
    }
    50% {
        opacity: 0.3;
    }
    100% {
        transform: scale(1.45);
        opacity: 0;
    }
}

.pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
}

.nav-bottom {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-content: flex-end;
    width: auto;
    height: auto;
    position: fixed;
    z-index: 8;
    bottom: 0px;
    
    padding: 5px;
    margin: 0px;
}

@media (max-width: 360px) {
    .nav-bottom {
        width: 320px;
    }
}

.whatsapp-button {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 60px;
    height: 60px;
    z-index: 8;
    transition: .3s;
    margin: 10px;
    padding: 7px;
    border: none;
    outline: none;
    cursor: pointer;
    border-radius: 50%;
    background-color: rgb(255, 255, 255);
}

.circle-anime {
    display: flex;
    position: absolute;
    justify-content: center;
    align-content: center;
    width: 60px;
    height: 60px;
    top: 15px;
    right: 15px;
    border-radius: 50%;
    transition: .3s;
    background-color: rgb(119, 187, 74);
    animation: pulse 1.2s 4.0s ease 4;
}

.popup-whatsapp {
    display: none;
    position: absolute;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: auto;
    height: auto;
    padding: 10px;
    bottom: 85px;
    right: -266px;
    transition: .5s;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    animation: slideInRight .6s 0.0s both;
}

.popup-whatsapp > div {
    margin: 5px;
}

@media (max-width: 680px) {
    .popup-whatsapp p {
        font-size: 0.9em;
    }
}

.popup-whatsapp > .content-whatsapp.-top p {
    color: rgb(88, 88, 88);
   
    font-weight: 400;
    font-size: 1.0em;
}

.popup-whatsapp > .content-whatsapp.-bottom {
    display: flex;
    flex-direction: row;
}

.closePopup {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 28px;
    height: 28px;
    margin: 0px 0px 15px 0px;
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: rgb(247, 96, 96);
}

.closePopup:hover {
    background-color: rgb(247, 29, 29);
}

.send-msPopup {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #ffffff;
    margin: 0px 0px 0px 5px;
    border: none;
    outline: none;
    cursor: pointer;
}

.send-msPopup:hover {
    background-color: #f8f8f8;
}

.is-active-whatsapp-popup {
    display: flex;
    animation: slideInRight .6s 0.0s both;
}

input.whats-input[type=text] {
    width: 250px;
    height: 40px;
    box-sizing: border-box;
    border: 0px solid #ffffff;
    border-radius: 20px;
    font-size: 1.0em;
    background-color: #ffffff;
    padding: 0px 0px 0px 10px;
    -webkit-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
    outline: none;
}

input.whats-input {
    color: rgba(68, 68, 68, 0.705);
}

input.whats-input[type=text]:focus {
    background-color: #f8f8f8;
    -webkit-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
}

.icon-whatsapp-small {
    width: 24px;
    height: 24px;
}

.icon-whatsapp {
    width: 45px;
    height: 45px;
}

.icon-font-color {
    color: #ffffff;
}

.icon-font-color--black {
    color: #333333;
}
