/*
Author: Dreamguys
Template Name: Dleohr Bootstrap4 admin template
Version: 1.0
*/

/*============================
 [Table of CSS]

1. General
2. Button
3. Select2
4. Header
5. Calendar
6. Mobile Menu
7. Breadcrump
8. Content
9. Table
10. Header Search
11. Login
12. Settings
13. Responsive

========================================*/

/*-----------------------
	 01. GENERAL
-------------------------*/




/* a, button {
    transition: 0.5s all;
    -webkit-transition: 0.5s all;
    -moz-transition: 0.5s all;
    -o-transition: 0.5s all;
    -ms-transition: 0.5s all;
    outline: 0px;
} */

a:hover,
a:focus {
    text-decoration: none;
}

.form-control:focus {
    outline: 0;
    box-shadow: none;
    border-color: #f1bd06 !important;
}

button:focus {
    outline: 0;
    box-shadow: none;
    border-color: transparent;
}

img {
    max-width: 100%;
}

input[type=date],
input[type=datetime-local],
input[type=email],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=text],
input[type=time],
input[type=url],
textarea,
select {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-shadow: none;
    box-shadow: none;
    outline: none;
    width: 100%;
    padding: 0 20px;
    border: 1px solid gray;
    border-radius: 4px;
}

h1 {
    font-size: 3.50rem;
    font-weight: 300;
}

h2 {
    font-size: 2.25rem;
}

h4 {
    font-size: 1.25rem;
}

h5 {
    font-size: 1.125rem;
    line-height: 2rem;
}

::-webkit-input-placeholder {
    color: #898f96;
}

::-moz-placeholder {
    color: #898f96;
}

::-ms-input-placeholder {
    color: #898f96;
}

::-ms-input-placeholder {
    color: #898f96;
}

::placeholder {
    color: #898f96;
}

.display-5 {
    font-size: 1.58rem;
}

.display-6 {
    font-size: 1.22rem;
}

.font-23 {
    font-size: 23px;
}

.no-bg-color {
    background-color: transparent;
}

.text-theme {
    color: #f1bd06;
}

.text-grey {
    color: #555555;
}

.text-danger {
    color: #f62d51 !important;
}

.table-responsive {
    white-space: nowrap;
}

.custom-badge {
    padding: 0.8rem 1.25rem;
    border-radius: 50em;
    background: #f1f3f6;
    margin-bottom: 5px;
    color: #fff;
    background-color: #6c757d;
}

.ctm-bg-danger {
    background: #ff6a6c;
}

.ctm-bg-warning {
    background: #feb71d;
}

.ctm-bg-info {
    background: #58c9e9;
}

.ctm-bg-success {
    background: #62d03a;
}

.ctm-border-radius {
    border-radius: 10px;
}

.ctm-btn-padding {
    padding: 10px 15px;
}

.ctm-text-sm {
    font-size: 14px;
    color: #888;
}

.ctm-padding {
    padding: 1.25rem;
}

.ctm-margin-btm {
    margin-bottom: 1.25rem !important;
}

.bg-theme {
    background-color: #3e007c;
}

.l-1 {
    left: 0.5rem;
}

.t-1 {
    top: 0.5rem;
}

.display-6 {
    font-size: 1.22rem;
}

.sidebar-overlay {
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1048;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    display: none;
    overflow: hidden;
}

.card:hover {
    box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08) !important;
    -webkit-box-shadow: 0 10px 6px -6px #777;
    -moz-box-shadow: 0 10px 6px -6px #777;
    box-shadow: 0 10px 6px -6px #777;
}

.accordion>.card:last-of-type {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

/*-----------------------
	 02.BUTTON
-------------------------*/

.btn-theme {
    background: #3e007c;
    border-bottom: 5px solid #f1bd06;
}

.btn-theme:hover,
.btn-theme:focus {
    box-shadow: 0 9px 14px 1px rgba(62, 0, 124, 0.18);
}

.btn-secondary {
    background: #7347c1;
}

.btn-outline {
    border: 1px solid #ffffff;
}

.text-primary {
    color: #3e007c !important;
}

.text-pink {
    color: #F00F89 !important;
}

.bg-primary {
    background-color: #3e007c !important;
}

.badge-primary {
    color: #fff;
    background-color: #3e007c;
}

a.text-primary:focus,
a.text-primary:hover {
    color: #f1bd06 !important;
}

a.text-warning:focus,
a.text-warning:hover {
    color: #dea705 !important;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    color: #dea705 !important;
}

.bootstrap-datetimepicker-widget table td.active,
.bootstrap-datetimepicker-widget table td.active:hover {
    background-color: #3e007c;
}



/*-----------------
	03. Select2
-----------------------*/


/*-----------------------
	 04.HEADER
-------------------------*/


.header {
    background-color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding: 0 15px 45px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.header-dropdown:hover>.dropdown-menu {
    display: block;
}

.top-header-section {
    padding-bottom: 25px;
    padding-top: 25px;
}

/*  */


/*--------------------------
	Sidebar
---------------------------------*/

.sidebar-wrapper .card a.active {
    background: #F77522;
}

.sidebar-wrapper a {
    border-bottom: 1px solid #ededed;
    display: flex;
    text-align: center;
    vertical-align: middle;
    align-items: center;
    justify-content: center;
    align-self: center;
    flex-direction: column;
}

.sidebar-wrapper a:hover {
    background: #ededed;
}

.sidebar-wrapper .first-slider-btn {
    border-top-left-radius: 10px;
}

.sidebar-wrapper .second-slider-btn {
    border-top-right-radius: 10px;
}

.sidebar-wrapper .last-slider-btn {
    border-bottom-right-radius: 10px;
}

.sidebar-wrapper .last-slider-btn1 {
    border-bottom-left-radius: 10px;
}

.ctm-border-right {
    border-right: 1px solid #ededed;
}

.ctm-border-left {
    border-left: 1px solid #ededed;
}

.ctm-border-top {
    border-top: 1px solid #ededed;
}

.ctm-border-full {
    border: 1px solid #ededed;
}

/* img:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
} */

a .avatar:hover>img {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

/*-----------------
	Loader
-----------------------*/

/*---------------------
	05. CALENDAR
----------------------*/

/*-----------------------
	06. MOBILE MENU 
-------------------------*/

.offcanvas-menu {
    position: fixed;
    height: 100%;
    width: 0px;
    top: 0;
    right: 0;
    background: #fff;
    z-index: 989999;
    overflow-y: scroll;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
    -webkit-box-shadow: 0 -2px 20px rgba(0, 0, 0, 0.3);
    box-shadow: 0 -2px 20px rgba(0, 0, 0, 0.3);
    padding-bottom: 25px;
}

/*-----------------
	07. BREADCRUMP
------------------------*/


/*-----------------------
   08. CONTENT
-------------------------*/



.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #F77522;
    border-color: #f1bd06;
}

.list-group-item:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.page-title {
    border-left: 3px solid #f1bd06;
    border-right: 3px solid #f1bd06;
}

.card {
    border: 1px solid #ededed;
    margin-bottom: 30px;
    border-radius: 10px;
}

.card-header {
    background: none;
    padding: 1.25rem;
    border-bottom: 1px solid #ededed;
}

.card-title {
    color: #333;
    margin-bottom: 20px;
}

hr {
    border-top: 1px solid #ededed;
}

.list-group-item {
    border: 1px solid #ededed;
}

.team-lead .media {
    align-items: center;
}

.avatar-upload {
    position: relative;
    max-width: 205px;
    margin: 15px auto;
}

.avatar-upload .avatar-edit {
    position: absolute;
    right: 12px;
    z-index: 1;
    top: 10px;
}

.avatar-upload .avatar-edit input {
    display: none;
}

.avatar-upload .avatar-edit input+label {
    display: inline-block;
    width: 34px;
    height: 34px;
    margin-bottom: 0;
    border-radius: 100%;
    background: #FFFFFF;
    border: 1px solid transparent;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    font-weight: normal;
    transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-edit input+label:hover {
    background: #f1f1f1;
    border-color: #d6d6d6;
}

.avatar-upload .avatar-edit input+label:after {
    content: "\f040";
 
    color: #757575;
    position: absolute;
    top: 3px;
    left: 0;
    right: 0;
    text-align: center;
    margin: auto;
}

.avatar-upload .avatar-preview {
    width: 200px;
    height: 200px;
    position: relative;
    border-radius: 10px;
    border: 6px solid #f3f3f3;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.avatar-upload .avatar-preview>div {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

/* .avatar-preview div {
    background-image: url(../img/logo2.png);
} */

.e-avatar:first-of-type {
    margin-left: 0 !important;
}

.e-avatar {
    background-color: #fff;
    border: 3px solid #e7e7e7;
    border-radius: 50%;
    height: 40px;
    position: relative;
    width: 40px;
    margin-left: -20px;
}

.e-avatar>img {
    border-radius: 50%;
    width: 100%;
}

.dash-card-container {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    min-height: 50px;
}

.dash-card-icon {
    align-items: center;
    display: flex;
    flex-direction: row;
    padding-right: 15px;
}

.dash-card-icon i {
    width: 20px;
}

.today-list .card-body .dash-card-content {
    color: #333;
}

.dash-card-content p {
    margin-bottom: 0;
}

.dash-card-avatars {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    padding: 0 15px;
    padding-right: 0;
}

.dash-card-content {
    align-items: center;
    display: flex;
    flex-direction: row;
}

.notice-board {
    display: flex;
    align-items: center;
}

.time-list {
    display: flex;
    flex-grow: 1;
}

.dash-stats-list {
    align-items: center;
    display: flex;
    flex-flow: column wrap;
    flex-grow: 1;
    padding-left: 15px;
    padding-right: 15px;
}

.dash-stats-list span {
    min-width: 105px;
}

.dash-stats-list h4 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 15px;
}

.dash-stats-list+.dash-stats-list {
    border-left: 1px solid #e7e7e7;
}

.dash-stats-list {
    align-items: center;
    display: flex;
    flex-flow: column wrap;
    flex-grow: 1;
    padding-left: 5px;
    padding-right: 5px;
}

.dash-widget .card-body {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.card-icon {
    float: left;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    background: #26a69a;
}

.card-icon .fa {
    font-size: 24px;
    margin: auto;
    color: #fff;
}

.card-right {
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.card-right .card-title {
    margin-bottom: 5px;
    font-size: 16px;
}

.card-right p {
    font-size: 20px;
    font-weight: 700;
}

.drop-active a {
    min-width: 90px;
}

.avatar {
    background-color: #3e007c;
    border-radius: 50%;
    color: #fff;
    display: inline-block;
    font-weight: 500;
    height: 38px;
    line-height: 34px;
    margin: 0 -17px 0 0;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    width: 38px;
    position: relative;
    white-space: nowrap;
    border: 3px solid #fff;
    box-shadow: 0px 1px 5px 0px rgba(53, 53, 53, 0.3);
}

.avatar:hover {
    color: #fff;
}

.avatar.avatar-xs {
    width: 24px;
    height: 24px;
}

.avatar>img {
    border-radius: 50%;
    display: block;
    overflow: hidden;
    width: 100%;
}

.nav-pills .nav-link.active,
.nav-pills .show>.nav-link {
    color: #fff;
    background-color: #3e007c;
    border-radius: 10px;
    border-bottom: 5px solid #f1bd06;
}

.nav-pills .nav-link {
    color: #3e007c;
}

.quicklink-sidebar-menu ul li a {
    display: block;
}

.header-menu-list div ul li a {
    display: block;
}

.yalign {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.admin-activ .recent-comment {
    max-height: 390px;
    overflow-y: auto;
    position: relative;
}

.btn-add {
    margin-bottom: 30px;
}

.last-card-row,
.last-card-row1 {
    margin-bottom: 0;
}

.grow {
    animation: bounce-in-right 3s ease;
}

@keyframes bounce-in-right {
    0% {
        opacity: 0;
        transform: translateX(2000px);
    }
    60% {
        opacity: 1;
        transform: translateX(-10px);
    }
    80% {
        transform: translateX(0px);
    }
    100% {
        transform: translateX(0);
    }
}

/*-----------------
	09. TABLE
-----------------------*/


/*-----------------
	10. HEADER SEARCH
---------------------*/

.top-nav-search {
    margin-right: 15px;
    margin-left: 15px;
}

.top-nav-search form {
    margin-top: 9px;
    position: relative;
    width: auto;
}

.top-nav-search .form-control {
   
    border-color: rgba(150, 148, 148, 0.9);
    border-radius: 50px;
    color: #fff;
    height: 42px;
    padding: 10px 50px 10px 15px;
}

.top-nav-search:hover {
    margin-right: 20px;
    transition: all 0.4s ease 0s;
}

.offcanvas-menu .top-nav-search form {
    margin-top: 20px;
}

.top-nav-search .btn {
    background-color: transparent;
    border-color: transparent;
    color: #fff;
    min-height: 40px;
    padding: 7px 15px;
    position: absolute;
    right: 0;
    top: 0;
}

.top-nav-search .form-control::-webkit-input-placeholder {
    color: #fff;
}

.top-nav-search .form-control::-moz-placeholder {
    color: #fff;
}

.top-nav-search .form-control:-ms-input-placeholder {
    color: #fff;
}

.top-nav-search .form-control::-ms-input-placeholder {
    color: #fff;
}

.top-nav-search .form-control::placeholder {
    color: #fff;
}

.top-nav-search.active form {
    display: block;
    left: 0;
    position: absolute;
}

.user-info a .user-avatar img {
    margin-bottom: 3px;
}

.document-up a {
    padding: 15px;
    background: #fff;
    margin-bottom: 0;
    border-radius: 5px;
    display: block;
    color: #333;
    border: 1px solid #e5e5e5;
}

.leave-img {
    width: 20px;
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #3E007C;
}

.widget-profile .profile-info-widget {
    display: block;
    text-align: center;
}

.widget-profile .profile-info-widget .booking-doc-img {
    display: inline-block;
    margin: 0 0 10px;
    width: auto;
    padding: 5px;
    background-color: #f7f7f7;
    border-radius: 50%;
}

.widget-profile .profile-info-widget .booking-doc-img img {
    border-radius: 50%;
    height: 60px;
    width: 60px;
}

.profile-info-widget {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    text-align: left;
}

.profile-info-widget .booking-doc-img {
    margin-right: 15px;
}

.profile-info-widget .booking-doc-img img {
    border-radius: 4px;
    height: 90px;
    width: 90px;
    object-fit: cover;
}

.profile-det-info {
    overflow: hidden;
}

.profile-det-info h3 {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

/*-----------------
	11. LOGIN
-----------------------*/

/*--------------------------
	14. RESPONSIVE
---------------------------*/

/*-----------------------
    Large devices (desktops, less than 1200px)
-------------------------*/

@media only screen and (max-width: 1199.98px) {
    .header-menu-list ul li a {
        font-size: 14px;
    }
    h1 {
        font-size: 2.75rem;
    }
    h2 {
        font-size: 2.03rem;
    }
    .h3,
    h3 {
        font-size: 1.40rem;
    }
    h5 {
        font-size: 1rem;
    }
    .last-card-row {
        margin-bottom: 30px;
    }
    .last-card-row1 {
        margin-bottom: 0;
    }
}

/*-----------------------
    Medium devices (tablets, less than 992px)
-------------------------*/

@media only screen and (max-width: 991.98px) {
    h4 {
        font-size: 1.20rem;
    }
    .header-menu-list ul li a {
        font-size: 18px;
    }
    .user-menu-items a {
        color: #212529;
    }
    .header {
        padding: 0 15px 45px;
    }
}

/*-----------------------
    Small devices (landscape phones, 768px)
-------------------------*/

@media only screen and (max-width: 767.98px) {
    .user-block .dropdown-menu {
        display: block;
        opacity: 0;
        visibility: hidden;
        top: calc(100% + 20px);
    }
    .dropdown:hover>.notification-dropdown-menu {
        opacity: 1;
        visibility: visible;
        top: 100%;
    }
    h1 {
        font-size: 2.75rem;
    }
    h2 {
        font-size: 2.03rem;
    }
    .h3,
    h3 {
        font-size: 1.30rem;
    }
    h5 {
        font-size: 1rem;
    }
    .header-wrapper div.append {
        width: 100%;
    }
    .header-wrapper div.append ul {
        display: none;
    }
    .header-wrapper div.append select {
        display: block;
        position: relative;
        background: #fff;
        font-size: 16px;
    }
    .header-menu-list {
        padding: 10px;
    }
    .header-wrapper div.append select:after {
        position: absolute;
        content: "\e874";
       
    }
    .offcanvas-menu .user-notification-block ul li a.menu-style {
        padding: 27px 14px 14px;
    }
    .user-notification-block ul li .badge {
        right: 23px;
    }
    .login-wrapper .loginbox .login-left {
        display: none;
    }
    .login-wrapper .loginbox {
        max-width: 450px;
        min-height: unset;
    }
    .login-wrapper .loginbox .login-right {
        float: none;
        padding: 1.875rem;
        width: 100%;
    }
    .top-nav-search form {
        width: 212px;
    }
}

/*-----------------------
    Extra small devices (portrait phones, less than 576px)
-------------------------*/

@media only screen and (max-width: 575.98px) {
  
    h2 {
        font-size: 1.67rem;
    }
    .h3,
    h3 {
        font-size: 1.20rem;
    }
    h4 {
        font-size: 1rem;
    }
 
    .top-header-section {
        padding-bottom: 5px;
        padding-top: 5px;
    }
    .page-wrapper {
        padding: 0;
        margin-top: -45px;
    }
    .header {
        padding: 0 0px 45px;
    }
    .card {
        margin-bottom: 15px;
    }
    .fc-toolbar .fc-right {
        display: inline-block;
        float: none;
        margin: 10px auto 0;
        width: 200px;
        clear: both;
    }
    .fc-toolbar .fc-left {
        float: none;
        margin: 0 auto;
        width: 200px;
    }
    .fc-toolbar .fc-center {
        display: inline-block;
        width: 100%;
        text-align: center;
    }
    .fc-toolbar .fc-center h2 {
        width: 100%;
    }
    .fc button {
        font-size: 14px;
    }
    .nav-pills .nav-link {
        padding: .5rem .5rem;
    }
    .tab-list-emp li a {
        padding: 0 5px;
    }
    .btn-add {
        margin-bottom: 15px;
    }
    .last-card-row1 {
        margin-bottom: 0;
    }
}

