.since_year{
    font-size: 106px;
    font-weight: 900;
}

.mask1 {
    -webkit-mask-image: url("https://thumbs.dreamstime.com/b/construction-team-work-re-talking-new-project-top-v-view-105908737.jpg?w=768");
    
    -webkit-mask-repeat: no-repeat;
   
  }
  .training_container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
    background-color: #F77522;
    /* background: linear-gradient(rgb(255, 78, 0), rgb(223, 114, 68)), url(/public/images/d1.jpeg); */
    padding: 20px;
    width: 100%;
  }
  
  .content_section,
  .info_section {
    display: grid;
    grid-template-columns: 1fr;
    /* grid-gap: 20px; */
  }
  
  .training_image {
    object-fit: cover;
    width: 100%;
    /* height: 190px; */
    border-radius: 10px;
  }
  
  .training_description p {
    color: white;
    text-align: justify;
    padding-top: 10px;
  }
  
  .search-box-btn {
    color: white;
    width: 170px;
    
    font-size: 18px;
    font-weight: 700;
    border: 2px dotted white;
    background-color: transparent;
    border-radius: 8px;
  }
  
  .map_container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 8px;
  }
  
  .map {
    width: 100%;
    height: 150px;
    border: 0;
    border-radius: 9px;
  }
  
  .course_container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 8px;
  }
  
  .course_image {
    width: 100%;
    height: 150px;
    border-radius: 9px;
    object-fit: cover;
  }
  
  @media (min-width: 768px) {
    .training_container {
      grid-template-columns: 1fr 1fr;
    }
  
    .content_section,
    .info_section {
      grid-template-columns: 1fr;
    }
    .head{
      display: none;
    }
    .deepak{display: none;}
    .join{
      width: 910px;
     }
   
  }
  
  @media (min-width: 1024px) {
    .training_container {
        grid-template-columns: 2fr 3fr;
        padding-left: 90px;
        padding-right: 90px;
    }
  }



  .responsive_team{
    margin-left: 400px;
  }


  @media screen  and (max-width: 568px){
    .responsive_team{
      margin-left: 15px;
    }
  }


  .course_item {
    text-align: center; /* Center align text and images */
  }
  
  .course_label {
    margin-bottom: 8px; /* Space between label and image */
    font-size: 18px; /* Adjust font size as needed */
    color: white; /* Or any other color that matches your design */
  }
  .container_3d {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
}

.content_3d {
    flex: 1;
    padding: 20px;
    margin-left: 70px;
}

.image_3d {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
   
    border-radius: 20px;
}

.image_3d .img_3d {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .container_3d {
        flex-direction: column;
        text-align: center;
    }

    .image_3d {
        margin-top: 20px;
    }

    .content_3d{
       margin-left: 0px;
    }
}


.onther_3d{
  position:absolute;
  top: 25px;
  left: 25px;
  z-index: 2;
}
.resposnive_home_aboutus{
  display: flex;
  justify-content: space-evenly;
  padding-left: 80px;
  padding-right: 80px;
  padding-bottom: 20px;
}

.content_home{
  margin-left: 30px;
}

.resposnive_2016{
  height: 130px;
  width: 200px;
}


@media screen and (max-width: 768px){
  .resposnive_home_aboutus{
    display: flex;
    flex-direction: column;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .imi{
    display: none;
  }

  .content_home{
          margin-left: 0px;
  }
  .head{
   
    height: 110px;
    font-size: 19px;
    color: rgb(255, 255, 255);
    background-color: #042038;
  }
  .deepak{
   color: black;
   margin-top: 20px;
   width: 90%;
   text-align: justify;
  }
  .deepak_info{
    display: none;
  }

  .resposnive_world{
    text-align: center;
  }
}

