/* Tab Container */
.tab-item-container {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border-right: 1px solid #cbd5e1;
  min-width: 150px;
}

/* Tab Item Styles */
.tab-item {
  padding: 12px;
  cursor: pointer;
  text-align: center;

  font-size: 18px;
  color: #475569;
}

.tab-item:hover {
  background-color: #f1f5f8;
}

.active-tab {
  background-color: #d7dfe9;
  font-weight: 600;
}





/* .active-tab-btn {
  background-color: transparent;
  border-width: 0px;
  border-color: transparent;
  font-family: 'Bree Serif';
  font-weight: 600;
  color: #ad2e2e;
  cursor: pointer;
} */


.active-tab-btn {
  border: none;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  color: #F77522;
  transition: all 1000ms;
  font-size: 15px;
  position: relative;
  overflow: hidden;
  outline: 2px solid #F77522;
}

.active-tab-btn:hover {
  color: #ffffff;
  transform: scale(1.1);
  outline: 2px solid #70bdca;
}

.active-tab-btn::before {
  content: "";
  position: absolute;
  left: -50px;
  top: 0;
  width: 0;
  height: 100%;
  background-color: #F77522;
  transform: skewX(45deg);
  z-index: -1;
  transition: width 1000ms;
}

.active-tab-btn:hover::before {
  width: 250%;
}


@media (max-width: 768px) {
  .tabitemcontainer {
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .tab-item {
    padding: 8px;
    font-size: 16px;
    margin-right: 5px;
    margin-bottom: 5px;
  }
}