.thank-you-container1 {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .card1 {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 600px; /* Increased max-width */
    width: 80%; /* Added width to center the card on the page */
    margin: 0 auto; /* Added to center the card on the page */
  }
  
  .social-icons {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .social-icons a {
    color: #333;
    font-size: 24px;
  }
  
  .contact-us {
    margin-top: 20px;
  }
  
  .contact-us h3 {
    margin-bottom: 10px;
  }
  
  .contact-us p {
    margin: 5px 0;
  }
  