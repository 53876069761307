.resposnive_logo{
    margin-top: 15px;
}

@media screen and (max-width: 768px) {
     .resposnive_logo{
           margin-top: 3px;
           margin-left: -6px;
     }
     .response_admin{
        display: none;
     }
     .response_logout{
          display: none;
     }
     .serve{
        margin-left: 10px;
     }
     .sbtn{
margin-left: -60px;
     }
    
   
}

@media screen and (min-width: 768px) {
    .cad{
        display: none;
    }
    .response_button{
        display: none;
    }
    .sbtn{
        margin-left: -15px;
    }

    .resposive_logout_in_mobile{
        display: none;
    }
    
}
.main-header .search5-box-btn {
    position: relative;
    float: left;
    width: 250px;
    height: 35px;

    font-size: 18px;
    color: #ffffff;
    padding: 0px;
   margin-top: 14px;
   margin-left: -15px;
    cursor: pointer;
    background: none;
    /* border-radius: 50%; */
    border: 1px dashed #fbb908;
    transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
}