.slide_wrap {
    position: relative;
    width: calc(3 * 620px); /* Adjust based on your requirement, for 3 slides */
    margin: 0 auto; /* Center align the slider */
    overflow: hidden;
  }
  
  .slide_show {
    display: flex;
    overflow: hidden;
    width: 100%;
  }
  
  .slide_img {
    display: flex;
    transition: margin-left 0.5s;
  }
  
  .slide {
    min-width: 300px; /* Width of each slide */
    margin-right: 20px; /* Space between slides */
    box-sizing: border-box;
    min-height: 300px;
  }
  
  .slide img {
    width: 100%;
    height: 100%; /* Make the image fill the height of the slider */
    object-fit: cover; /* Ensure the image covers the entire area without distortion */
  }
  
  .slide_btn {
    position: absolute;
    top: 50%;
    width: 100%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
  }
  
  .slide_btn button {
    background: rgba(0, 0, 0, 0.5);
    border: none;
    color: white;
    font-size: 2rem;
    padding: 0.5rem 1rem;
    cursor: pointer;
    transition: background 0.3s;
  }
  
  .slide_btn button:hover {
    background: rgba(0, 0, 0, 0.8);
  }
  
  @media (max-width: 1200px) {
    .slide_wrap {
      max-width: 1240px; /* For 2 slides (2 * 620px) */
    }
  }
  
  @media (max-width: 800px) {
    .slide_wrap {
      max-width: 620px;
      margin-left: 30px; /* For 1 slide (1 * 620px) */
    }
  
    .slide_show {
      height: 300px; /* Adjust height for smaller screens */
    }
  
    .slide {
      margin-right: 10px; /* Less margin for smaller screens */
    }
  
    .slide_btn button {
      font-size: 1.5rem;
    }
  }