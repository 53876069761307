.button_help {
    display: inline-block;
    border-radius: 7px;
    border: none;
    background: #fbb908;
    color: white;

    text-align: center;
    font-size: 13px;
    
    width: 10em;
    padding: 1em;
    transition: all 0.4s;
    cursor: pointer;
   }
   
   .button_help .gethelp {
    cursor: pointer;
    display: inline-block;
    position: relative;
    transition: 0.4s;
   }
   
   .button_help .gethelp:after {
    content: 'freely';
    position: absolute;
    opacity: 0;
    top: 0;
    right: -10px;
    transition: 0.7s;
   }
   
   .button_help:hover .gethelp {
    padding-right: 3.55em;
   }
   
   .button_help:hover .gethelp:after {
    opacity: 4;
    right: 0;
   }